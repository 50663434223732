<template>
  <section class="home-galleries">
    <div class="container-padding">
      <div class="home-galleries__inner">
        <div class="home-galleries__header">
          <h2 class="home-galleries__title">События</h2>
          <router-link to="/galleries" class="btn btn--main btn--md">
            Просмотреть все
          </router-link>
        </div>
        <div v-if="galleries && galleries.length" class="home-galleries__content">
          <GalleriesCardComponent v-for="(gallery, i) in galleries" :key="i" :gallery="gallery" />
        </div>
        <h3 v-else class="no-info">В данный момент информация отсутствует</h3>
      </div>
    </div>
  </section>
</template>

<script>
import GalleriesCardComponent from "../../galleries/components/GalleriesCardComponent.vue";

export default {
  name: "HomeGalleriesComponent",
  computed: {
    galleries() {
      return this.$store.state.home.galleries?.data || [];
    }
  },
  components: {
    GalleriesCardComponent
  }
};
</script>

<style lang="stylus">
.home-galleries {
  &__inner {
    display grid
    grid-gap 30px
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
    +below(700px) {
      flex-direction column
      align-items flex-start
      grid-gap 15px
    }
  }

  &__title {
    font-weight 700
    font-size 1.75rem
    line-height 42px
    color var(--dark)
    +below(700px) {
      font-size 1.5rem
      line-height 36px
    }
  }

  &__content {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 20px
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(700px) {
      grid-template-columns 1fr
    }
  }
}
</style>
